import React, { useEffect, useState } from "react"
import Clock from "../countdown/clock"
import Tilt from "react-parallax-tilt"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import logo from "../../images/logo-dark.svg"
import "./coming-soon.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons"
import addToMailchimp from "gatsby-plugin-mailchimp"

import AnimatedBackground from "../animated-background/animated-background"
import { Button } from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
const count = { deadline: process.env.GATSBY_COMING_SOON_DATE }

const ComingSoon = () => {
  const [state, setState] = useState({ values: { EMAIL: "" }, errors: [] })
  const [message, setMessage] = useState(
    "Wollen Sie benachrichtigt werden, wenn's weitergeht?"
  )
  const [success, setSuccess] = useState(false)
  const handleSubmit = (event, errors, values) => {
    event.persist()
    setState({ values: values, errors: errors })
  }

  useEffect(() => {
    if (state.values.EMAIL.length === 0) {
      setMessage("Wollen Sie benachrichtigt werden, wenn's weitergeht?")
      // setSuccess(false)
    } else {
      addToMailchimp(state.values.EMAIL, state.values) // listFields are optional if you are only capturing the email address.
        .then(data => {
          setMessage(data.msg)
          setSuccess(true)
        })
        .catch(e => {
          setMessage(e.msg)
          setSuccess(false)
        })
    }
  }, [state])

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className="container">
      <div className="cs-wrap">
        <div className="hero-wrap soon-wrap">
          <div className="impulse-wrap">
            <div>
              <div
                style={{
                  position: "relative",
                  top: "80%",
                  height: "20%",
                  width: "80%",
                  display: "table",
                }}
              >
                <span
                  style={{
                    fontSize: "3rem",
                    display: "table-cell",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                ></span>
              </div>
            </div>
            <Tilt
              tiltAngleXInitial={3}
              tiltAngleYInitial={3}
              style={{
                transformStyle: "preserve-3d",
                transform: "perspective(800px)",
              }}
            >
              <div className="half-hero-wrap section-entry">
                <h1 style={{ transform: "translateZ(1000px) scale(1.1)" }}>
                  Hallo!
                  <br />
                  Mein Name ist Jan
                  <br />
                  Meine Webseite wird <br />
                  gerade überarbeitet <br />
                  <span>Bald wieder online</span>
                </h1>

                <Clock deadline={count.deadline} />
                <h4
                  id={`notification ${success ? "success" : ""}`}
                  dangerouslySetInnerHTML={{ __html: message }}
                ></h4>
                <div className="clearfix"></div>
                <div className="subscribe-form">
                  <AvForm
                    id="subscribe"
                    name="subscribe"
                    className="fl-wrap"
                    onSubmit={handleSubmit}
                  >
                    <AvField
                      className="enteremail"
                      id="email"
                      name="EMAIL"
                      label="Email"
                      type="email"
                      spellCheck="false"
                    />
                    <button
                      type="submit"
                      id="subscribe-button"
                      className="subscribe-button"
                    >
                      <FontAwesomeIcon icon={faPaperPlane} />
                      Anmelden
                    </button>
                    <label
                      htmlFor="subscribe-email"
                      className="subscribe-message"
                    ></label>
                  </AvForm>
                </div>
              </div>

              <logo style={{ height: "790px" }} />
            </Tilt>
          </div>
        </div>
        <div className="pattern-bg"></div>
        <AnimatedBackground />
      </div>
    </div>
  )
}

export default ComingSoon
