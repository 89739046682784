import React, { useContext } from "react"
import Layout from "../components/layout"
import ComingSoonComponent from "../components/coming-soon/coming-soon"
import LayoutContext from "../context/LayoutContext"

const ComingSoon = () => {
  const layoutContext = useContext(LayoutContext)
  layoutContext.update({ layout: "slim" })
  return (
    <Layout layout="slim">
      <ComingSoonComponent />
    </Layout>
  )
}

export default ComingSoon
