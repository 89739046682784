import React from "react"
import Dot from "./animated-background-dot"
import "./animated-background.css"
export default function AnimatedBackground(amount = 12) {
  return (
    <div className="half-bg-dec">
      <Dot amount={20} />
    </div>
  )
}
